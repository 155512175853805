import * as React from "react";
import {
    createBrowserRouter,
} from "react-router-dom";
import LoginScreen from "../pages/login";
import HomeScreen from "../pages/home";
import UplaodScreen from "../pages/Upload/index";


const Publicrouter = createBrowserRouter([
    {
        path: "/",
        element: <LoginScreen/>,
    },
]);

const Privaterouter = createBrowserRouter([
    {
        path: "/",
        element: <UplaodScreen/>,
    },
]);
export {Privaterouter,Publicrouter}