import React, { useEffect, useState } from 'react';
import Sidebar from '../../component/sideBar';
import Header from "../../component/header/index";
import style from "./home.module.css"
import { Table, Modal, Upload, message, Button } from "antd"
import { UploadOutlined } from '@ant-design/icons';
import ReactTimeAgo from 'react-time-ago'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


const UplaodScreen = () => {
    const [dataSource, setDataSource] = useState([]);
    const fetchDataAll = async () => {
        setLoading(true)
        const response = await fetch(`https://bartenderbackend.bazazi.co/excel/GetExcelDataProcessed/1/5000000/${search == ""?null:search}`, {
            headers: {
                'Content-Type': 'application/json',
                key: 'inventory'
            }
        });
        const data = await response.json();
        setDataSource(data.data);
        setLoading(false)
    };



    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)
    const [refresh, setRefreash] = useState(false)
    const [search, setSearch] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

    const handlePaginationChange = (newPagination) => {
        setPagination(newPagination);
    };

    useEffect(() => {
        fetchDataAll()
    }, [refresh, search, pagination]);


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const props = {
        name: 'excelFile',
        accept: ".xlsx",
        action: 'https://bartenderbackend.bazazi.co/excel/ProcessExcelFile',
        headers: {
            authorization: 'authorization-text',
            key: 'inventory'
        },
        onChange(info) {
            console.log(info,'info')
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
                setRefreash(!refresh)
                handleCancel()
            } else if (info.file.status === 'error') {
                setRefreash(!refresh)
                handleCancel()
                message.error(info.file.response.message)
                setRefreash(!refresh)
            }
        },
    };


    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 200,
        },
        {
            title: 'Street Address',
            dataIndex: 'street_address',
            key: 'street_address',
            width: 200,
        },
        {
            title: 'City',
            key: 'city',
            dataIndex: 'city',
            width: 150,

        },
        {
            title: 'State',
            key: 'state',
            dataIndex: 'state',
            width: 100,
        },
        {
            title: 'Zip Code',
            key: 'zip_code',
            dataIndex: 'zip_code',
            width: 150,
        },
        {
            title: 'Country',
            key: 'country',
            dataIndex: 'country',
            width: 150,
        },
        // {
        //     title: 'Work order number',
        //     key: 'work_order_number',
        //     dataIndex: 'work_order_number',
        //     width: 200,
        // },
        // {
        //     title: 'State',
        //     key: 'state',
        //     dataIndex: 'state',
        //     width: 100,
        // },
        // {
        //     title: 'Zip code',
        //     key: 'zip_code',
        //     dataIndex: 'zip_code',
        //     width: 100,
        // },
        // {
        //     title: 'City',
        //     key: 'city',
        //     dataIndex: 'city',
        //     width: 100,
        // },
        // {
        //     title: 'Country',
        //     key: 'country',
        //     dataIndex: 'country',
        //     width: 300,
        // },
        // {
        //     title: 'Remarks',
        //     key: 'remarks',
        //     dataIndex: 'remarks',
        //     width: 100,
        // },
        // {
        //     title: 'Order no usps',
        //     key: 'shipping_order_no_usps',
        //     dataIndex: 'shipping_order_no_usps',
        //     width: 200,
        // },
        // {
        //     title: 'Shipping time',
        //     key: 'shipping_time',
        //     dataIndex: 'shipping_time',
        //     width: 200,
        // },
        // {
        //     title: 'Side view',
        //     key: 'side_view',
        //     dataIndex: 'side_view',
        //     width: 200,
        // },
        // {
        //     title: 'Side view',
        //     key: 'side_view',
        //     dataIndex: 'side_view',
        //     width: 200,
        // },
        // {
        //     title: 'Receipt flag',
        //     key: 'sign_for_receipt_flag',
        //     dataIndex: 'sign_for_receipt_flag',
        //     width: 200,
        // },

    ];
    const [isModalOpen, setIsModalOpen] = useState(false);


    function convertArrayOfObjectsToCSV(array) {
        const header = Object.keys(array[0]).join(',');
        const rows = array.map(obj => {
            return Object.values(obj).map(val => {
                if (typeof val === 'string' && val.includes(',')) {
                    return `"${val}"`;
                }
                return val;
            }).join(',');
        });
        return header + '\n' + rows.join('\n');
    }

    function downloadCSV(array, filename) {
        const csv = convertArrayOfObjectsToCSV(array);
        console.log(csv, 'csjjjjjj')
        const blob = new Blob([csv], { type: 'text/csv' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
    return (
        <>
            <Modal title="Upload" footer={<></>} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className='w-100 d-flex align-items-center justify-content-center py-5'>
                    <Upload {...props}>
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </div>
            </Modal>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-2 px-0'>
                        <Sidebar />
                    </div>
                    <div className='col-md-10 px-0'>
                        <Header Link={'Home/Upload'} search={setSearch} />
                        <div className={style.MainBody}>
                            <div className='w-100 d-flex align-items-center justify-content-between'>
                                <h2>Upload</h2>
                                <div className={style.AddNewBtnBox}>
                                    <button className='mr-2' onClick={() => downloadCSV(dataSource, 'example.csv')}>Download All</button>
                                    <button onClick={showModal}>Upload New</button>
                                </div>
                            </div>
                            <div className='pt-4'>
                                <Table
                                    pagination={false}
                                    size="middle" scroll={{
                                        x: 2800,
                                    }} loading={loading} columns={columns} dataSource={dataSource} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UplaodScreen