import React from "react";
import style from './button.module.css'

const BorderBtn =({text,onClick})=>{
    return(
        <div>
            <button onClick={onClick} className={style.MainBtn}>{text}</button>
        </div>
    )
}

export {BorderBtn}