import React, { useState } from 'react';
import style from './login.module.css';
import { TextInput, PasswordInput } from '../../component/input';
import { BorderBtn } from '../../component/button';
import { useNavigate } from "react-router-dom";
import { message } from 'antd';

const LoginScreen = () => {
    const navigation = useNavigate();
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    const login = () => {
        if (email == "admin@logomish.com") {
            if (password == "123456789") {
                localStorage.setItem('isLogin', true)
                setTimeout(() => {
                    window.location.reload(false)
                }, 1000);
            }
            else {
                message.error("Invalid email or password")
            }
        }
        else {
            message.error("Invalid email or password")
        }
    }
    return (
        <>
            <div className='container-fluid'>
                <div className='container'>
                    <div className='row align-items-center justify-content-center'>
                        <div className={`col-md-6 ${style.mainLoginCardWrapper}`}>
                            <div className={style.mainLoginCard}>
                                <h4>Login.</h4>
                                <h2>Welcome back!</h2>
                                <div className='pt-4'>
                                    <TextInput onChange={setEmail} placeholder={'Email here'} label={"Mail ID"} />
                                </div>
                                <div className='pt-4'>
                                    <PasswordInput onChange={setPassword} placeholder={'Password here'} label={"Password"} />
                                </div>
                                <div className='pt-2'>
                                    <BorderBtn onClick={() => login()} text={'Log in'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default LoginScreen