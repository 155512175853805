import React from "react";
import style from "./header.module.css" 

const Header = ({Link,search}) => {
    const logoutBtn =()=>{
        localStorage.removeItem('isLogin')
        window.location.reload(false)
    }
    return (
        <div className={style.MainHeader}>
            <div className={style.navigations}>
                <p>{Link}</p>
            </div>
            <div className={style.SearchbarSide}>
                <input type="text" placeholder="Search Here" onChange={(e)=>{search(e.target.value)}} className="mr-2"/>
                <button onClick={logoutBtn}>Logout</button>
            </div>
        </div>
    )
}

export default Header;