import React, { useState } from "react";
import style from './sidebar.module.css'
import { useLocation,useNavigate } from "react-router-dom";




const Sidebar = () => {
    const navigation = useNavigate();
    const location = useLocation();
    const [sidebarList, setSidebarList] = useState([
        {
            id: 2,
            title: 'Upload',
            link: '/upload',
            icon:"fa fa-file"
        },
    ])
    return (
        <div className={style.mainSideBar}>
            {sidebarList.map(data =>
                <div onClick={()=>navigation(data?.link)} className={data.link == location.pathname?style.sideBarListActive:style.sideBarList}>
                    <i class={data.icon} aria-hidden="true"></i>
                    <p>{data.title}</p>
                </div>
            )}
        </div>
    )
}

export default Sidebar;