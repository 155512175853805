import React, { useState } from 'react';
import style from './input.module.css'

const TextInput = ({ placeholder, label, onChange }) => {
    return (
        <div className='d-flex flex-column'>
            <label className={style.mainInputLabel}>{label}</label>
            <input placeholder={placeholder} className={style.mainInput} onChange={(e) => onChange(e.target.value)} type="text" />
        </div>
    )
}

const PasswordInput = ({ placeholder, label, onChange }) => {
    const [isClose, setIsClose] = useState(true)
    return (
        <div className='d-flex flex-column'>
            <label className={style.mainInputLabel}>{label}</label>
            <div className={style.mainInputAbsoluteLayer}>
                <input placeholder={placeholder} onChange={(e) => onChange(e.target.value)} type={!isClose ? 'text' : 'password'} className={style.mainInput} />
                <div className={style.mainInputEyeBtn}>
                    <i onClick={() => setIsClose(!isClose)} class={isClose ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i>
                </div>
                <div className='w-100 d-flex align-items-center pt-2'>
                    <div className='w-50'></div>
                    <div className='w-50 d-flex align-items-center justify-content-end'>
                        <p className={style.fonrgotPasswordText}>Forget password?</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { TextInput, PasswordInput }